// ...

export const PimcoreRoleEnum = {
  /** Always returns this auth role */  DEFAULT:      <const>'ROLE_PIMCORE_USER',
  /** HT Admin Role (centrala)      */  HT_ADMIN:     <const>'ROLE_API_ADMIN',
  /** HT Billing User (fakturant)   */  HT_BILLING:   <const>'ROLE_API_BILLING',
  /** Authenticated as WorkerGroup  */  WORKER_GROUP: <const>'ROLE_API_WORKER',
  /** Authenticated service technician  */  SERVICE_TECHNICIAN: <const>'ROLE_API_SERVICE_TECHNICIAN',
  /** Editor pobočky Fakturuje HT & Montuje HT */  BRANCH_MONTAGE_HT: <const>'ROLE_API_BRANCH_BILLING_HT_MONTAGE_HT',
  /** Editor pobočky Fakturuje HT & Montuje Pobočka */  BRANCH_MONTAGE_BRANCH:  <const>'ROLE_API_BRANCH_BILLING_HT_MONTAGE_BRANCH',
}
export type PimcoreRole = typeof PimcoreRoleEnum[keyof typeof PimcoreRoleEnum]
