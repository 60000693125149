import { PimcoreRole, PimcoreRoleEnum } from '@/models/pimcore/restapi/PimcoreApiUserRole'
import { Requirement, RequirementEnum, UserRole, UserRoleEnum } from './roles.enum'
import { ApiUserRole } from '@/common/componentHelpers/useRole'


export const getUserRole = (roles?: PimcoreRole[]): UserRole => {
  if (!roles || !roles.length) return UserRoleEnum.none

  for (const r of roles) {
    if (r === PimcoreRoleEnum.DEFAULT) continue
    switch (r) {
      case PimcoreRoleEnum.HT_ADMIN: return UserRoleEnum.ht_admin
      case PimcoreRoleEnum.HT_BILLING: return UserRoleEnum.ht_billing
      case PimcoreRoleEnum.BRANCH_MONTAGE_HT: return UserRoleEnum.branch_montage_ht
      case PimcoreRoleEnum.BRANCH_MONTAGE_BRANCH: return UserRoleEnum.branch_montage_branch
      case PimcoreRoleEnum.WORKER_GROUP: return UserRoleEnum.worker
      case PimcoreRoleEnum.SERVICE_TECHNICIAN: return UserRoleEnum.worker
    }
  }

  return UserRoleEnum.none
}

export const getPimcoreRole = (role?: UserRole | ApiUserRole): PimcoreRole[] => {
  switch (role) {
    case 'ht_admin': return ['ROLE_API_ADMIN']
    case 'ht_billing': return ['ROLE_API_BILLING']
    case 'branch_montage_ht': return ['ROLE_API_BRANCH_BILLING_HT_MONTAGE_HT']
    case 'branch_montage_branch': return ['ROLE_API_BRANCH_BILLING_HT_MONTAGE_BRANCH']
    case 'worker': return ['ROLE_API_WORKER']
    default: if (role && role !== 'none') return [role]; else return []
  }
}


/**
 * Vue Router Helper function.
 * Determines whether the user may enter certain route.
 * Every route has defined Auth.Requirement
 */
export const mayAccess = (userRole: UserRole, destinationRequirement: Requirement[]) => {
  // If the page route does not require any roles, simply don't check for anything
  if (destinationRequirement.includes(RequirementEnum.none)) return true

  if (destinationRequirement.includes(RequirementEnum.any)) {
    // if the destination requires at least any auth, search for default role owned by all users.
    return userRole !== UserRoleEnum.none
  }

  // Otherwise check specifically for the required role
  return destinationRequirement.includes(userRole)
}
