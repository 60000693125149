import { User } from '@/common/logic/auth/auth.types'
import { clog } from '@/common/utils/AppConsole'

import type { ApiUserRole } from '@/common/componentHelpers/useRole'

export type PimcoreUserRequest = {

  email: string
  password?: string
  name: string
  workerGroupId?: number
  branchOfficeId?: number
  roles: ApiUserRole[] | string
  branchOfficesId?: number[]
}
export const PimcoreUserRequest = (o?: User, password?: string): PimcoreUserRequest => {
  const r: PimcoreUserRequest = {
    email: o?.email || '',
    name: o?.firstName || o?.name || '',
    roles: o?.roles as ApiUserRole[] || [],
  }

  if (password) r.password = password
  if (o?.workerGroup) r.workerGroupId = parseInt(o.workerGroup.id!)
  if (o?.branchOffice) r.branchOfficeId = parseInt(o.branchOffice.id!)
  if (o?.branchOffices) r.branchOfficesId = o.branchOffices.map(a => Number(a.id))

  clog(`CREATED THIS REQUEST ${r}`)
  return r
}
