// ...
import i18nPlugin from '@/plugins/i18n.plugin'

import type { ApiUserRole } from '@/common/componentHelpers/useRole'

export const UserRoleEnum = {
  /** User is not authenticated     */  none:         <const>'none', // Acts as null within roles
  /** HT Admin Role (centrala)      */  ht_admin:     <const>'ht_admin',
  /** HT Billing User (fakturant)   */  ht_billing:   <const>'ht_billing',
  /** Authenticated as WorkerGroup  */  worker:       <const>'worker',
  /** Branch Admin (pobocka)        */  branch_montage_ht: <const>'branch_montage_ht',
  /** Branch user (fakturant)       */  branch_montage_branch:  <const>'branch_montage_branch', // ? may be deleted in the future
}
export type UserRole = typeof UserRoleEnum[keyof typeof UserRoleEnum]
/** Formal type rename for better naming */

export const UserRolePretty = (role: UserRole | ApiUserRole) => {
  switch (role) {
    case 'ht_admin':      return i18nPlugin.t('roles.admin').toString()
    case 'ht_billing':    return i18nPlugin.t('roles.billing').toString()
    case 'branch_montage_ht':  return i18nPlugin.t('roles.branchMontage').toString()
    case 'branch_montage_branch':   return i18nPlugin.t('roles.branchMontageBranch').toString()
    case 'worker':        return i18nPlugin.t('roles.worker').toString()
    // new roles
    case 'ROLE_API_ADMIN': return i18nPlugin.t('roles.admin').toString()
    case 'ROLE_API_BILLING': return i18nPlugin.t('roles.billing').toString()
    case 'ROLE_API_BRANCH_BILLING_HT_MONTAGE_HT': return i18nPlugin.t('roles.branchMontage').toString()
    case 'ROLE_API_BRANCH_BILLING_HT_MONTAGE_BRANCH': return i18nPlugin.t('roles.branchMontageBranch').toString()
    case 'ROLE_API_WORKER': return i18nPlugin.t('roles.worker').toString()
    case 'ROLE_API_SERVICE_TECHNICIAN': return i18nPlugin.t('roles.serviceTechnician').toString()
    // default
    case 'none':          return ''
  }
}

export const RequirementEnum = {
  ...UserRoleEnum,
  any: <const>'any',
}
export type Requirement = typeof RequirementEnum[keyof typeof RequirementEnum]


export const UserRoleGroupEnum = {
  ht:     <const>'ht',
  branch: <const>'branch',
  worker: <const>'worker',
  none:   <const>'none',
}
export type UserRoleGroup = typeof UserRoleGroupEnum[keyof typeof UserRoleGroupEnum]

export const UserRoleGroup = (role: UserRole): UserRoleGroup => {
  switch (role) {
    case 'ht_admin':
    case 'ht_billing':
      return 'ht'
    case 'branch_montage_ht':
    case 'branch_montage_branch':
      return 'branch'
    case 'worker':
      return 'worker'
    case 'none':
      return 'none'
  }
}
